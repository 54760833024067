import { useState, useCallback } from "react";
import * as Sentry from "@sentry/react";
import axios from "axios";
import { MFAMethodOptions, MFAMethods } from "@sumit-platforms/types";

export interface MFAVerificationResponse {
  success: boolean;
  message?: string;
}

export const useMFA = ({
  config,
  onEnrollSuccess,
  onEnrollFail,
  onVerificationSuccess,
  onVerificationFailure,
}: {
  config: any;
  onEnrollSuccess?: (type: MFAMethodOptions, qrCode?: string) => void;
  onEnrollFail?: (error: string, type: string) => void;
  onVerificationSuccess?: (response: MFAVerificationResponse) => void;
  onVerificationFailure?: (error: Error) => void;
}): {
  validateMfaSession: typeof validateMfaSession;
  enrollSMS: typeof enrollSMS;
  enrollTOTP: typeof enrollTOTP;
  sendSMSCode: typeof sendSMSCode;
  resendSMS: typeof resendSMS;
  verifySMSCode: typeof verifySMSCode;
  verifyOTPCode: typeof verifyOTPCode;
  disableMFA: typeof disableMFA;
  recover: typeof recover;
  isLoading: typeof isLoading;
  error: typeof error;
} => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const mfaUrl = `${config.server.host}/${config.server.mfa}`;

  const enrollSMS = useCallback(async () => {
    try {
      setIsLoading(true);
      setError(null);

      const enrollment = await axios.post(`${mfaUrl}/enroll/sms`, {
        credentials: "include",
      });

      const { success, error, message, type } = await enrollment.data;

      if (!success) {
        if (onEnrollFail) {
          onEnrollFail(error, type);
        }
        throw new Error("Failed to enroll MFA SMS");
      }

      if (onEnrollSuccess) {
        onEnrollSuccess(MFAMethods.sms);
      }

      return success;
    } catch (error) {
      const msg = "SMS enrollment failed";
      setError(msg);
      Sentry.captureException(error);
      throw error;
    } finally {
      setIsLoading(false);
    }
  }, [mfaUrl]);

  const resendSMS = useCallback(async () => {
    try {
      setIsLoading(true);
      setError(null);

      const enrollment = await axios.post(`${mfaUrl}/resend/sms`, {
        credentials: "include",
      });

      const { success } = await enrollment.data;

      if (!success) {
        throw new Error("Failed to resend SMS");
      }

      return success;
    } catch (error) {
      const msg = "SMS enrollment failed";
      setError(msg);
      Sentry.captureException(error);
      throw error;
    } finally {
      setIsLoading(false);
    }
  }, [mfaUrl]);

  const sendSMSCode = useCallback(
    async (phoneNumber: string) => {
      try {
        setIsLoading(true);
        setError(null);

        const verification = await axios.post(`${mfaUrl}/verify`, {
          body: { phoneNumber },
          credentials: "include",
        });

        const { success } = await verification.data;

        if (!success) {
          throw new Error("Failed to validate SMS code");
        }

        return success;
      } catch (error) {
        const msg = "SMS verification failed";
        setError(msg);
        Sentry.captureException(error);
        throw error;
      } finally {
        setIsLoading(false);
      }
    },
    [mfaUrl]
  );

  const verifySMSCode = useCallback(
    async (code: string) => {
      try {
        setIsLoading(true);
        setError(null);

        const verification = await axios.post(
          `${mfaUrl}/verify?callbackPath=${window.location}`,
          {
            code,
          },
          {
            withCredentials: true,
          }
        );

        const { success } = await verification.data;

        if (!success) {
          const error = new Error("Failed to validate SMS code");
          if (onVerificationFailure) {
            onVerificationFailure(error);
          }

          return verification.data;
        }

        if (onVerificationSuccess) {
          onVerificationSuccess(verification.data);
        }

        return success;
      } catch (error) {
        const msg = "SMS verification failed";
        setError(msg);
        Sentry.captureException(error);
        throw error;
      } finally {
        setIsLoading(false);
      }
    },
    [mfaUrl, onVerificationSuccess, onVerificationFailure]
  );

  const verifyOTPCode = useCallback(
    async (code: string) => {
      try {
        setIsLoading(true);
        setError(null);

        const verification = await axios.post(
          `${mfaUrl}/verify?callbackPath=${window.location}`,
          {
            code,
          },
          {
            withCredentials: true,
          }
        );

        const { success } = await verification.data;

        if (!success) {
          const error = new Error("Failed to validate OTP code");
          if (onVerificationFailure) {
            onVerificationFailure(error);
          }

          return verification.data;
        }

        if (onVerificationSuccess) {
          onVerificationSuccess(verification.data);
        }

        return success;
      } catch (error) {
        const msg = "OTP verification failed";
        setError(msg);
        Sentry.captureException(error);
        throw error;
      } finally {
        setIsLoading(false);
      }
    },
    [mfaUrl, onVerificationSuccess, onVerificationFailure]
  );

  const enrollTOTP = useCallback(async () => {
    try {
      setIsLoading(true);
      setError(null);

      const response = await fetch(`${mfaUrl}/enroll/totp`, {
        method: "POST",
        credentials: "include",
      });

      const result: any = await response.json();
      console.log({ result });
      if (!response.ok) {
        throw new Error(result.message || "Failed to setup TOTP");
      }

      if (onEnrollSuccess) {
        onEnrollSuccess(MFAMethods.totp, result.qrCode);
      }

      return result;
    } catch (err) {
      const error = err instanceof Error ? err : new Error("Unknown error");
      setError(error.message);
      Sentry.captureException(error);
      throw error;
    } finally {
      setIsLoading(false);
    }
  }, [mfaUrl]);

  const verifyTOTP = useCallback(
    async (token: string) => {
      try {
        setIsLoading(true);
        setError(null);

        const response = await fetch(`${mfaUrl}/verify-totp`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ token }),
          credentials: "include",
        });

        const result: MFAVerificationResponse = await response.json();

        if (!response.ok || !result.success) {
          const errorMessage = result.message || "TOTP verification failed";
          setError(errorMessage);

          if (onVerificationFailure) {
            onVerificationFailure(new Error(errorMessage));
          }

          return result;
        }

        if (onVerificationSuccess) {
          onVerificationSuccess(result);
        }

        return result;
      } catch (err) {
        const error = err instanceof Error ? err : new Error("Unknown error");
        setError(error.message);
        Sentry.captureException(error);

        if (onVerificationFailure) {
          onVerificationFailure(error);
        }

        throw error;
      } finally {
        setIsLoading(false);
      }
    },
    [mfaUrl, onVerificationSuccess, onVerificationFailure]
  );

  const disableMFA = useCallback(async () => {
    try {
      setIsLoading(true);
      setError(null);

      const response = await fetch(`${mfaUrl}/disable`, {
        method: "POST",
        credentials: "include",
      });

      const result = await response.json();

      if (!response.ok) {
        throw new Error(result.message || "Failed to disable MFA");
      }

      return result;
    } catch (err) {
      const error = err instanceof Error ? err : new Error("Unknown error");
      setError(error.message);
      Sentry.captureException(error);
      throw error;
    } finally {
      setIsLoading(false);
    }
  }, [mfaUrl]);

  const recover = useCallback(
    async (idUser: string | number) => {
      try {
        const response = await axios.post(`${mfaUrl}/recover`, { idUser });
        return response.data;
      } catch (e) {
        console.error(e);
        return null;
      }
    },
    [mfaUrl]
  );

  const validateMfaSession = async (): Promise<{
    phone: string | null;
  } | null> => {
    try {
      const response = await axios.get(
        `${mfaUrl}/validate-session?callbackPath=${window.location}`
      );

      return response?.data;
    } catch (e: any) {
      if (e.response?.data?.redirectUrl) {
        window.location.href = e.response.data.redirectUrl;
      }
      console.error(`Failed to validate mfa session.`, e);
      return null;
    }
  };

  return {
    enrollSMS,
    enrollTOTP,
    sendSMSCode,
    verifySMSCode,
    verifyOTPCode,
    resendSMS,
    disableMFA,
    isLoading,
    error,
    recover,
    validateMfaSession,
  };
};
