import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Box,
  Button,
  IconButton,
  ThemeProvider,
  Typography,
} from "@mui/material";
import { Button as LoadingButton } from "../../core/Button/Button";
import OtpInput from "react-otp-input";
import { sumitLogoBlack } from "@sumit-platforms/common-assets/images";
import { useTranslation } from "react-i18next";
import { bazarTheme } from "../../bazar-theme";
import { faLeftFromBracket } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { MFAMethodOptions, MFAMethods } from "@sumit-platforms/types";
import { useLocation } from "react-router-dom";
import { Tooltip } from "@sumit-platforms/ui-bazar";

import "../../../style/auth.scss";
import "./mfa.scss";

interface props {
  type: MFAMethodOptions;
  verifyOTPCode: (code: string) => Promise<void>;
  validateMfaSession: () => Promise<{ phone: string | null } | null>;
  logOut: () => Promise<void>;
}

export const VerifyOTPCode: React.FC<props> = ({
  verifyOTPCode,
  type,
  validateMfaSession,
  logOut,
}: props) => {
  const { t } = useTranslation();

  const { state } = useLocation();
  const [otp, setOtp] = useState("");
  const [isSignoutLoading, setIsSignoutLoading] = useState(false);

  useEffect(() => {
    validateMfaSession;
  }, []);

  const otpApps = useMemo(() => {
    return [
      {
        name: "Google Authenticator",
        link: "https://support.google.com/accounts/answer/1066447?hl=en&co=GENIE.Platform%3DAndroid",
      },
      {
        name: "Apple Passwords",
        link: "https://apps.apple.com/us/app/passwords/id6473799789",
      },
    ];
  }, []);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");

  const handleVerifyCode = async (value: string) => {
    setIsLoading(true);
    const code = value;
    if (code.length !== 6) {
      setIsLoading(false);
      return;
    }
    await verifyOTPCode(code)
      .catch((error) => {
        console.error(error);
        setError(error.response.data.error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleOtpInputChange = useCallback(
    (value: string) => {
      setOtp(value);
      if (value.length === 6) {
        handleVerifyCode(value);
      }
    },
    [handleVerifyCode]
  );

  const handleLogout = useCallback(async () => {
    setIsSignoutLoading(true);
    try {
      await logOut();
    } catch (e) {
      setIsSignoutLoading(false);
    }
  }, []);

  return (
    <ThemeProvider theme={bazarTheme}>
      <div className="VerifyOTPCode AuthPage MFA">
        <div className="authContainer">
          <div className="headerTitle">
            <Tooltip title={t("menu_logout")}>
              <IconButton
                aria-label="menu_logout"
                onClick={handleLogout}
                sx={{
                  position: "absolute",
                  left: 16,
                  top: 16,
                  height: 50,
                  width: 50,
                }}
                disabled={isSignoutLoading}
              >
                <FontAwesomeIcon icon={faLeftFromBracket} />
              </IconButton>
            </Tooltip>
            <img src={sumitLogoBlack} alt="Sumit-AI" className="headerLogo" />
          </div>
          <Box sx={{ mb: 3 }}>
            <Typography
              variant="h5"
              component="h1"
              gutterBottom
              align="center"
              color="text.primary"
            >
              {t("mfa_verify_otp_title")}
            </Typography>
          </Box>
          {state?.qrCode && (
            <Box sx={{ mb: 3 }} className="qrBox">
              <Typography sx={{ mb: 2 }} variant="body1" color="text.secondary">
                {t("mfa_otp_qr_instructions")}
              </Typography>
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <img src={state.qrCode} className="qrCode" />
              </Box>
            </Box>
          )}

          <Box>
            <Typography variant="body1" gutterBottom color="text.secondary">
              {t("mfa_verify_otp_instructions")}
            </Typography>
            <Box
              sx={{ display: "flex", justifyContent: "center", margin: 2 }}
              className="codeVerification"
            >
              <OtpInput
                shouldAutoFocus={true}
                value={otp}
                onChange={handleOtpInputChange}
                numInputs={6}
                renderInput={(props) => <input {...props} className="digit" />}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                mb: 1,
                minHeight: 30,
              }}
              className="codeError"
            >
              <Typography
                variant="body1"
                gutterBottom
                align="center"
                color="error"
              >
                {t(error || "")}
              </Typography>
            </Box>

            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <LoadingButton
                type="submit"
                variant="contained"
                color="primary"
                onClick={() => handleVerifyCode(otp)}
                loading={isLoading}
                disabled={otp.length < 6}
              >
                {t("mfa_verify_code_button")}
              </LoadingButton>
            </Box>
          </Box>
          {type === MFAMethods.sms && (
            <Typography
              variant="body2"
              align="center"
              color="text.secondary"
              sx={{ mt: 4 }}
            >
              {t("mfa_didnt_receive_code")}{" "}
              <Button color="primary">{t("mfa_resend_code")}</Button>
            </Typography>
          )}
        </div>
      </div>
    </ThemeProvider>
  );
};
